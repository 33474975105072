/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'ProximaNova';
  font-weight: 400;
  src: local('ProximaNova'),
    url(./fonts/ProximaNova/proximanova-regular-webfont.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 500;
  src: local('ProximaNova'),
    url(./fonts/ProximaNova/proximanova-medium-webfont.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 600;
  src: local('ProximaNova'),
    url(./fonts/ProximaNova/proximanova-semibold-webfont.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 700;
  src: local('ProximaNova'),
    url(./fonts/ProximaNova/proximanova-bold-webfont.woff) format('woff');
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background: #f0f4fa 0% 0% no-repeat padding-box;
  margin: 0;
  font: 400 14px Open Sans;
  overflow: hidden;
}

.klwLRq {
  color: #6d81a6;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background-color: #d4dff5;
  border-radius: 10px;
}
